/* Landscape view phones */
@media (min-aspect-ratio: 1 / 1) and (max-width: 812px) {}

@media (min-aspect-ratio: 1 / 1) {}

@import "../../../dist/variables";
@import "../../../dist/functions";

@media (min-width: 0) {
  #presentation-side {
    -ms-flex: none;
    flex: none;
    padding: 40px;
    height: 30vh;

    h1 {
      font-size: 30px;
      margin-bottom: 20px;
    }

    p {
      font-size: 14px;
    }
  }

  #presentation-body {
    height: 70vh;
  }
}

@media (min-width: map-get($grid-sizes, "xxs")) {}

@media (min-width: map-get($grid-sizes, "xs")) {}

@media (min-width: map-get($grid-sizes, "sm")) {}

@media (min-width: map-get($grid-sizes, "md")) {}

@media (min-width: map-get($grid-sizes, "lg")) {
  #presentation-side {
    -ms-flex: 0 0 400px;
    flex: 0 0 400px;
    padding: 40px;
    height: 100vh;

    h1 {
      font-size: 40px;
      margin-bottom: 20px;
    }

    p {
      font-size: 18px;
    }
  }

  #presentation-body {
    height: 100vh;
  }

  #presentation-video {
    object-fit: cover;
    width: 100%;
    height: 100vh;
  }
}

@media (min-width: map-get($grid-sizes, "xl")) {}

@media (min-width: map-get($grid-sizes, "xxl")) {}

@media (min-width: map-get($grid-sizes, "xxxl")) {}

/* Mobile specific */
@media (max-width: map-get($grid-sizes, "xl")) and (orientation: portrait) {
  #presentation-side {
    -ms-flex: none;
    flex: none;
    padding: 40px;
    height: 50vh;

    .container-fluid {
      margin-top: 24%;
      align-items: center !important;
    }

  }

  #presentation-body {
    height: 50vh;
  }

  #presentation-video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

@media (max-width: map-get($grid-sizes, "xs")) and (orientation: portrait) {

  #presentation-side {
    padding: 0px 20px 0px 40px;
  }
}

@media (max-width: map-get($grid-sizes, "xxs")) and (orientation: portrait) {}