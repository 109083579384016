$grid-sizes: (xxxs: 0,
xxs: 320px,
xs: 568px,
sm: 667px,
md: 768px,
lg: 992px,
xl: 1200px,
xxl: 1440px,
xxxl: 1600px);

$home-title-size: 40px;

$colors: (background-dark: #212326,
background-accent: #f7f7f7,

primary:#E2001A,
primary-dark:darkRed);