@import "../../../dist/variables";
@import "../../../dist/functions";

.homepage-background-image {
    position: absolute;
    opacity: 0.5;
    bottom: 0;

    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, 0%);
    -ms-transform: translate(-50%, 0%);
    -webkit-transform: translate(-50%, 0%);
    -moz-transform: translate(-50%, 0%);
    -o-transform: translate(-50%, 0%);

    -webkit-animation: fadein 10s;
    -moz-animation: fadein 10s;
    -ms-animation: fadein 10s;
    -o-animation: fadein 10s;
    animation: fadein 10s;
}


#homepage-header {
    height: 8vh;
    margin: 0px 50px 0px 50px;

    img {
        height: 3vh;
        width: auto;
    }
}

#homepage-body {
    height: 80vh;
    margin: 0px 20% 0px 20%;

    h2 {
        font-family: 'Source Sans Pro';
        margin-bottom: 14px;
        font-weight: 400;
    }

    h1 {
        font-weight: 300;
        margin-bottom: 0px;

        b {
            font-weight: 400;
        }
    }

    button {
        margin-top: 20px;
        padding: 2px 40px 2px 40px;

        -webkit-animation: fadein 1s;
        -moz-animation: fadein 1s;
        -ms-animation: fadein 1s;
        -o-animation: fadein 1s;
        animation: fadein 1s;
    }

    .button-placeholder {
        height: 50px;
        width: 100%;
    }

    .col {
        margin-bottom: 8vw;
    }
}

#homepage-footer {
    height: 12vh;

    .col-2:first-child button,
    .col-2:first-child button:focus {
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;

        img {
            width: 20px;
            height: auto;

            -webkit-animation-name: bounce;
            animation-name: bounce;
            -webkit-animation-duration: 2s;
            animation-duration: 2s;
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
        }
    }

    .col-5:last-child img {
        margin-right: 40px;
    }
}