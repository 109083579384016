#references {
    display: -ms-flex;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    height: 100%;

    #references-body {
        -ms-flex: 1 0 0;
        flex: 1 0 0;

        .slick-slide div {
            overflow: hidden;
        }
    }
}