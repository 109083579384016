@import "../../../dist/variables";

/* default styles*/
#contact::after {
    opacity: 0.5;
    z-index: -1;

}

#contact-header {
    text-align: center;
    background-color: rgba(21, 23, 26, 0.55);

    button {
        padding: 2px 40px 2px 40px;
    }
}


#contact-body {
    overflow: hidden;
    background-color: rgba(21, 23, 26, 0.55);

    h2 {
        font-family: 'Source Sans Pro', sans-serif;
    }

    div {
        width: 100%;
        height: 100%;
    }



    .slick-slider {
        .slick-item-info {
            color: white;
            text-align: center;
        }

        article {
            opacity: 0.5;
        }
    }
}

#contact-footer {
    background-color: rgba(21, 23, 26, 0.55);

    button {
        color: white;
        opacity: 0.5;
        background-color: transparent;
        -webkit-appearance: none;
        -moz-appearance: none;
        border: none;
        outline: none;

        cursor: pointer;
    }
}
