/* default styles*/
#presentation-side {
    -ms-flex: 0 0 400px;
    flex: 0 0 400px;
    padding: 80px;
}

#presentation-body {
    overflow: hidden;
    padding-left: 0px;
    padding-right: 1px;
}

#presentation-video {
    object-fit: cover;
    width: 100%;
    height: 100%;
}