@mixin setTitleSize($size) {
    h1 {
        font-size: $size;
    }
}

@mixin setSubtitleSize($size) {
    h2 {
        font-size: $size;
    }
}

@mixin scaleMarginByFactor($factor) {
    margin: 0px 5% 0px 5%;
}

/*
@mixin responsive($size) {
    @media (min-width: map-get($grid-sizes, $size)) {
        @content;
    }
}

@mixin responsive-portrait($size) {
    @media (max-width: map-get($grid-sizes, $size)) and (orientation:portrait) {
        @content;
    }
}

@function color($color-name) {
    @return map-get($map: , $key: )
}*/