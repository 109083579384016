#marketing .row {
    height: auto;
    padding-right: 40px;

    article {
        font-size: 14px;
    }

    .col-7:first-child {
        padding-right: 40px
    }
}

#marketing-info {
    margin-top: 30px;

    .row {
        padding-right: 0px;
    }


    .col-4 {
        padding-bottom: 30px;
    }

    header {
        font-family: 'Oswald', sans-serif;
        font-size: 30px;
        font-weight: 500;
    }

    span {
        font-size: 10px;
    }
}

@media (min-width: map-get($grid-sizes, "xxs")) {
    #marketing-info {
        margin-top: 0px;
        padding-right: 20px;
    }

    #marketing .row {
        header {
            padding-top: 20px;
            font-size: 20px;
        }

        h2 {
            font-size: 30px;
        }

        article {
            font-size: 12px;
            margin-top: 18px;
        }

        span {
            font-size: 12px;
        }
    }
}

@media (min-width: map-get($grid-sizes, "lg")) {
    #marketing.container-fluid>.row:first-child {
        height: 100px;
    }

    #marketing .row {
        h2 {
            font-size: 40px;
        }

        article {
            font-size: 14px;
            margin-top: 0px;
        }
    }
}

@media (min-width: map-get($grid-sizes, "xl")) {
    #marketing .row {

        article {
            font-size: 16px;
        }

        span {
            font-size: 14px;
        }

        h2 {
            font-size: 40px;
        }
    }
}

@media (min-width: map-get($grid-sizes, "xxxl")) {
    #marketing .row {

        header {
            font-size: 40px;
        }

        h2 {
            font-size: 40px;
        }

        li {
            font-size: 18px;
        }

        article {
            font-size: 18px;
        }

        span {
            font-size: 16px;
        }
    }
}