@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 0.5;
    }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 0.3;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 0.3;
    }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 0.3;
    }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 0.3;
    }
}

@-webkit-keyframes bounce {

    0%,
    100%,
    20%,
    50%,
    80% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    40% {
        -webkit-transform: translateY(5px);
        transform: translateY(5px)
    }

    60% {
        -webkit-transform: translateY(3px);
        transform: translateY(3px)
    }
}

@keyframes bounce {

    0%,
    100%,
    20%,
    50%,
    80% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    40% {
        -webkit-transform: translateY(5px);
        transform: translateY(5px)
    }

    60% {
        -webkit-transform: translateY(3px);
        transform: translateY(3px)
    }
}