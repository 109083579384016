#about {
    padding-right: 0px;
    height: 100%;

    .row {
        height: 100%;
    }
}

#about-side {
    background-color: #fff;
}

#about-body {
    background-color: #f7f7f7;
    height: 100%;

    div {
        height: 100%;
    }

    .nav {
        background-color: #f7f7f7;
    }

    #about-content-container {
        display: -ms-flex;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
    }

    .container-fluid {
        padding-right: 0px;
    }
}