html {
    touch-action: manipulation;
}

body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #212326;
}

.dark-theme {
    background-color: #212326;
    color: #fff;
    overflow: hidden;
}

.light-theme {
    background-color: #fff;
    color: #000;
    overflow: hidden;
}

.text-dark {
    color: "black"
}

/* latin */
@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-display: auto;
    font-weight: 400;
    src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), url(https://fonts.gstatic.com/s/sourcesanspro/v11/6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7lujVj9w.woff2) format('woff2');
}

/* latin */
@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-display: auto;
    font-weight: 300;
    src: local('Oswald Light'), local('Oswald-Light'), url(https://fonts.gstatic.com/s/oswald/v16/TK3hWkUHHAIjg75-sh0Tus9CAZek1w.woff2) format('woff2');
}


/* latin */
@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-display: auto;
    font-weight: 400;
    src: local('Oswald Regular'), local('Oswald-Regular'), url(https://fonts.gstatic.com/s/oswald/v16/TK3iWkUHHAIjg752GT8Gl-1PKw.woff2) format('woff2');
}

/* latin */
@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-display: auto;
    font-weight: 500;
    src: local('Oswald Medium'), local('Oswald-Medium'), url(https://fonts.gstatic.com/s/oswald/v16/TK3hWkUHHAIjg75-6hwTus9CAZek1w.woff2) format('woff2');
}


div,
h1,
h2,
h3,
h4,
h5,
h6,
li,
p {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
}

h1 {
    font-family: 'Oswald', sans-serif;
    font-size: 50px;
    font-weight: 300;
    margin-bottom: 50px;
}

h2 {
    font-family: 'Oswald', sans-serif;
    font-size: 40px;
    font-weight: 300;
    margin-bottom: 30px;
}


.accordion button,
.accordion button:hover,
.accordion button:active,
.accordion button:focus {
    color: #E2001A;
}

/* Buttons */

.w-primary {
    color: white;
    background-color: #E2001A;
    border-radius: 40px;
    padding: 2px 80px 2px 80px;
}

.w-primary:focus,
.w-primary:hover {
    color: white;
    background-color: darkRed;
}

.w-secondary {
    color: #000;
    margin-right: 25px;
    border-radius: 0px;
    padding: 0px;
    background-color: transparent;
    border: none;
    outline: none;
}

.w-secondary:last-child {
    margin-right: 0px;
}

.w-secondary.active {
    color: #E2001A;
    outline: none;
    padding-bottom: 4px;
    border-bottom: 1px solid #E2001A;
}

.w-secondary:focus,
.w-secondary:hover {
    color: #E2001A;
    outline: none !important;
}

div[class*='styles_modal__'] {
    width: 100%;
}

/* everything before iPad Portrait size */
@media (max-width: 767px) {
    h1 {
        font-size: 30px;
        margin-bottom: 10px;
    }

    h2 {
        font-size: 24px;
        margin-bottom: 10px;
    }

    p {
        font-size: 14px;
    }
}