@import "../../../dist/variables";
@import "../../../dist/functions";

@media (min-width: 0) {
    .homepage-background-image {
        width: 110%;
        height: auto;
        bottom: -5vw;
    }

    #homepage-header {
        height: 8vh;
        margin: 0px 0px 0px 0px;
    }

    #homepage-body {
        @include setSubtitleSize($home-title-size * 0.25);
        @include setTitleSize($home-title-size * 0.75);
        margin: 0px 0px 0px 20px;
    }
}

@media (min-width: map-get($grid-sizes, "xxs")) {
    .homepage-background-image {
        width: 100%;
    }
}

@media (min-width: map-get($grid-sizes, "xs")) {
    .homepage-background-image {
        width: 90%;
        bottom: -8vw;
    }

    #homepage-body {
        @include setSubtitleSize($home-title-size * 0.35);
    }

    #homepage-footer {
        .col-5:last-child img {
            margin-right: 20px;
            max-width: 80px;
        }
    }
}

@media (min-width: map-get($grid-sizes, "sm")) {
    #homepage-header {
        margin: 0px 20px 0px 20px;
    }

    .homepage-background-image {
        width: 80%;
        bottom: -10vw;
    }

    #homepage-body {
        @include setSubtitleSize($home-title-size * 0.35);
        @include setTitleSize($home-title-size * 0.9);
        margin: 0px 40px 0px 40px;
    }

    #homepage-footer {
        .col-5:last-child img {
            margin-right: 20px;
            max-width: 100px;
        }
    }
}

@media (min-width: map-get($grid-sizes, "md")) {


    #homepage-body {
        @include setTitleSize($home-title-size * 1);
    }
}

@media (min-width: map-get($grid-sizes, "lg")) {
    #homepage-header {
        margin: 0px 4px 0px 40px;
    }

    #homepage-body {
        @include setSubtitleSize($home-title-size * 0.45);
        @include setTitleSize($home-title-size * 1.2);
    }
}

@media (min-width: map-get($grid-sizes, "xl")) {
    .homepage-background-image {
        bottom: -13vw;
    }

    #homepage-body {
        @include setTitleSize($home-title-size * 1.25);
    }

    #homepage-footer {
        .col-5:last-child img {
            margin-right: 40px;
            max-width: 200px;
        }
    }
}

@media (min-width: map-get($grid-sizes, "xxl")) {}

@media (min-width: map-get($grid-sizes, "xxxl")) {}



/* Mobile specific */
@media (max-width: map-get($grid-sizes, "xl")) and (orientation:portrait) {
    .homepage-background-image {
        width: 100%;
        bottom: 5vh;
    }
}


@media (max-width: map-get($grid-sizes, "xs")) and (orientation:portrait) {
    .homepage-background-image {
        width: 100%;
        bottom: 4vh;
    }

    #homepage-body {
        margin: 0px 0px 0px 0px;
        @include setTitleSize($home-title-size * 0.95);
    }

    #homepage-body .col {
        margin: 0px 0px 120px 0px;
    }
}

@media (max-width: map-get($grid-sizes, "xxs")) and (orientation:portrait) {
    #homepage-body {
        @include setTitleSize($home-title-size * 0.75);
    }
}