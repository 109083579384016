
gw-contact-form-wrapper,
gw-contact-form-wrapper *{
    box-sizing: border-box;
}
.gw-contact-form-wrapper {
    position: relative;
}
.gw-contact-form-overlay {
    display: block;
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;

    z-index: 1;

    padding: 15px;
    color: black;
    position: absolute;
    left: 5%;
    top: 5%;

    width: 90%;
    height: 70%;

    background: rgba(255,255,255,0.85);

    &.visisble {
        opacity: 1;
        pointer-events: auto;
    }
}
label {
    width: 100%;
    &.required {
        font-weight: bold;

        display: block;
    }
}
p {
    text-align: center;
    font-weight: 500;
}
input[type=text],input[type=email], select, textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
}
input[type=checkbox] {
    margin-right: 10px;
}
.container {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
}
.gw-contact-form-send-button-wrapper {
    text-align: center;

    .gw-contact-form-send-button {
        font-size: 20px;
        margin-top: 10px;
    }
}
.gw-success {
    background: rgba(255,255,255,0.9);
    color: green;

    font-weight: bold;
    font-size: 170%;

    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.gw-error {
    color: red;

    input[type='text'] {
        border-color: red;
    }
}

.gw-message-label {
    display: none;
}
