#feedback-body {
    padding: 0px;
    overflow: hidden;

    .row {
        height: 100vh;
    }

    .slick-slider {

        ul {
            div {
                opacity: 0.5;
            }

            .slick-active div {
                opacity: 1;
            }
        }

        .slick-item-info {
            color: white;
            position: absolute;

            background: rgba(0,0,0,0.4);
            padding: 20px;

            p:last-child {
                opacity: 0.5;
            }

            .logo-img {
                width: 150px;
                height: auto;
                margin: 10px 0px;
                display: block;
            }
        }
    }
}
