/* import what we need to override */
@import "./variables";
@import "./bootstrap/functions";
@import "./bootstrap/variables";

/* set the overriding variables */
$grid-breakpoints: (xxxs: 0,
xxs: map-get($grid-sizes, "xxs"),
xs: map-get($grid-sizes, "xs"),
sm: map-get($grid-sizes, "sm"),
md: map-get($grid-sizes, "md"),
lg: map-get($grid-sizes, "lg"),
xl: map-get($grid-sizes, "xl"),
xxl: map-get($grid-sizes, "xxl"),
xxxl: map-get($grid-sizes, "xxxl"));

$container-max-widths: (xxxs: 0,
xxs: map-get($grid-sizes, "xxs"),
xs: map-get($grid-sizes, "xs"),
sm: map-get($grid-sizes, "sm"),
md: map-get($grid-sizes, "md"),
lg: map-get($grid-sizes, "lg"),
xl: map-get($grid-sizes, "xl"),
xxl: map-get($grid-sizes, "xxl"),
xxxl: map-get($grid-sizes, "xxxl"));

@import "./bootstrap/bootstrap.scss";