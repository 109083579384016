@import "../../../dist/variables";
@import "../../../dist/functions";


@media (min-width: 0) {
    #about-side {
        padding: 20px;
        height: 30vh;

        h1 {
            font-size: 30px;
        }

        p {
            font-size: 14px;
        }
    }

    #about-body {
        padding-top: 20px;
        height: 70vh;

        #about-content {
            -ms-flex: 1 0 0;
            flex: 1 0 0;
            overflow: hidden;
        }

        #about-nav {
            -ms-flex: 0 0 36px;
            flex: 0 0 36px;
            height: 36px;

            h3 {
                font-size: 10px;
                margin-bottom: 2px;
            }

            .w-secondary {
                margin-right: 10px;
            }
        }
    }
}

@media (min-width: map-get($grid-sizes, "xxs")) {
    #about {
        .isDesktop {
            #about-body {
                #about-nav {
                    -ms-flex: 0 0 58px;
                    flex: 0 0 58px;
                    height: 58px;
                }
            }
        }
    }
}

@media (min-width: map-get($grid-sizes, "xs")) {}

@media (min-width: map-get($grid-sizes, "sm")) {}

@media (min-width: map-get($grid-sizes, "md")) {
    #about {
        .isDesktop {
            #about-side {
                -ms-flex: 0 0 240px;
                flex: 0 0 240px;
                padding: 80px 10px 40px 40px;
                height: 100vh;
            }

            #about-body {
                padding-top: 22px;
                height: 100vh;

                #about-content {
                    -ms-flex: 1 0 0;
                    flex: 1 0 0;
                    overflow: hidden;
                }
            }
        }
    }
}

@media (min-width: map-get($grid-sizes, "lg")) {
    #about {
        .isDesktop {
            #about-side {
                -ms-flex: 0 0 30vw;
                flex: 0 0 30vw;

                h1 {
                    margin-bottom: 50px;
                    font-size: 40px;
                }

                p {
                    font-size: 18px;
                }
            }

            #about-body {
                #about-nav {
                    -ms-flex: 0 0 58px;
                    flex: 0 0 58px;
                    height: 58px;

                    h3 {
                        font-size: 14px;
                    }

                    .w-secondary {
                        margin-right: 15px;
                    }
                }
            }
        }
    }
}

@media (min-width: map-get($grid-sizes, "xl")) {
    #about {
        .isDesktop {
            #about-side {
                -ms-flex: 0 0 400px;
                flex: 0 0 400px;
                padding: 100px 80px 80px 80px;
            }
        }
    }
}

@media (min-width: map-get($grid-sizes, "xxl")) {
    #about {
        .isDesktop {
            #about-body {
                #about-nav {
                    -ms-flex: 0 0 74px;
                    flex: 0 0 74px;
                    height: 74px;

                    h3 {
                        font-size: 20px;
                    }

                    .w-secondary {
                        margin-right: 30px;
                    }
                }
            }
        }
    }
}

@media (min-width: map-get($grid-sizes, "xxxl")) {}



/* Mobile specific */
@media (max-width: map-get($grid-sizes, "xl")) and (orientation:portrait) {}

@media (max-width: map-get($grid-sizes, "lg")) and (orientation:portrait) {
    #about {
        .isMobile {
            #about-side {
                height: 100%;
            }
        }
    }
}

@media (max-width: map-get($grid-sizes, "xs")) and (orientation:portrait) {}

@media (max-width: map-get($grid-sizes, "xxs")) and (orientation:portrait) {}

#about-body.isMobile {
    height: 100%;
    padding: 0px;
}