@import "../../../dist/variables";
@import "../../../dist/functions";


@media (min-width: map-get($grid-sizes, "xxxs")) {
    #feedback {
        .row {
            flex-wrap: wrap;
        }
    }

    #feedback-side {
        -ms-flex: none;
        flex: none;
        padding: 20px 20px 20px 20px;

        h1 {
            margin-bottom: 10px;
            font-size: 30px;
        }

        p {
            font-size: 14px;
        }
    }

    #feedback-body {
        -ms-flex: none;
        flex: none;
        height: 65vh;

        .slick-slider {
            ul {
                top: 55vh;
            }

            .slick-item-image {
                height: 60vw;
                width: auto;
            }

            .slick-item-info {
                bottom: 0%;
                margin: 20px 10px 0px 20px;
                width: 90vw;

                h2 {
                    font-size: 18px;
                }

                article {
                    margin-bottom: 20px;
                    font-size: 12px;
                }

                p {
                    margin-bottom: 0px;
                    font-size: 12px;
                }
            }

            .slick-item-info.position-right {
                //margin-left: 55vw;
                //width: 300px;
            }
        }
    }
}

@media (min-width: map-get($grid-sizes, "xxs")) {}

@media (min-width: map-get($grid-sizes, "xs")) {}

@media (min-width: map-get($grid-sizes, "sm")) {}

@media (min-width: map-get($grid-sizes, "md")) {
    #feedback {
        .row {
            flex-wrap: wrap;
        }
    }

    #feedback-side {
        -ms-flex: none;
        flex: none;
        height: 38.7vh;
        padding: 20px 80px 20px 80px;

        h1 {
            margin-bottom: 28px;
        }
    }

    #feedback-body {
        height: 61.3vh;

        .slick-slider {
            ul {
                top: 55vh;
            }

            .slick-item-image {
                height: auto;
                width: 100vw;
            }

            .slick-item-info {
                bottom: 0%;
                margin: 20px 60px;
                width: 400px;

                h2 {
                    font-size: 18px;
                }

                article {
                    margin-bottom: 20px;
                    font-size: 12px;
                }

                p {
                    margin-bottom: 0px;
                    font-size: 12px;
                }
            }

            .slick-item-info.position-right {
                margin-left: 55vw;
                width: 300px;
            }
        }
    }
}

@media (min-width: map-get($grid-sizes, "lg")) {
    #feedback-side {
        padding: 60px 80px 80px 80px;

        h1 {
            margin-bottom: 40px;
            font-size: 40px;
        }

        p {
            font-size: 18px;
        }
    }
}

@media (min-width: map-get($grid-sizes, "xl")) {
    #feedback {
        .row {
            flex-wrap: nowrap;
        }
    }

    #feedback-side {
        -ms-flex: 0 0 400px;
        flex: 0 0 400px;
        padding: 80px;
    }

    #feedback-body {
        height: 100%;
        -ms-flex: 1 0 0;
        flex: 1 0 0;

        .slick-slider {
            ul {
                top: 95vh;
            }

            .slick-item-image {
                height: 100vh;
                width: auto;
            }

            .slick-item-info {
                margin: 120px;

                h2 {
                    font-size: 18px;
                }

                article {
                    font-size: 14px;
                }

                p {
                    font-size: 14px;
                }
            }

            .slick-item-info.position-right {
                margin-left: 40vw;
            }
        }
    }
}

@media (min-width: map-get($grid-sizes, "xxl")) {
    #feedback-body {
        .slick-slider {
            .slick-item-info {
                h2 {
                    font-size: 20px;
                }

                article {
                    font-size: 18px;
                }

                p {
                    font-size: 18px;
                }
            }

            .slick-item-info.position-right {
                margin-left: 40vw;
            }
        }
    }
}

@media (min-width: map-get($grid-sizes, "xxxl")) {
    #feedback-body {
        .slick-slider {
            .slick-item-info {
                margin: 180px;
            }

            .slick-item-info.position-right {
                margin-left: 45vw;
                width: 400px;
            }
        }
    }
}



/* Mobile specific */
@media (max-width: map-get($grid-sizes, "xl")) and (orientation:portrait) {
    #feedback-body {
        .slick-slider {
            .slick-item-image {
                height: 61.3vh;
                width: auto;
            }
        }
    }
}

@media (max-width: map-get($grid-sizes, "lg")) and (orientation:portrait) {
    #feedback-body {
        height: 100%;
    }


}

@media (max-width: map-get($grid-sizes, "xs")) and (orientation:portrait) {}

@media (max-width: map-get($grid-sizes, "xxs")) and (orientation:portrait) {}

.isMobile #feedback {

    .row {
        height: 100%;
    }
}
