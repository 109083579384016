@import "../../../dist/variables";
@import "../../../dist/functions";


@media (min-width: map-get($grid-sizes, "xxxs")) {
    #contact-header {
        height: 90vh;
        padding: 220px 8% 0px 8%;

        h1:first-child {
            font-size: 26px;
        }

        button {
            margin-top: 20px;
        }
    }

    #contact-footer {
        height: 10vh;
        padding: 0;
    }
}

@media (min-width: map-get($grid-sizes, "xxs")) {}

@media (min-width: map-get($grid-sizes, "xs")) {}

@media (min-width: map-get($grid-sizes, "sm")) {
    #contact-header {
        height: 90vh;
        padding: 220px 8% 0px 8%;

        h1:first-child {
            font-size: 36px;
        }

        h1 {
            margin-bottom: 0px;
        }

        button {
            margin-top: 20px;
        }
    }

    #contact-footer {
        height: 10vh;
        padding: 0;


    }



}

@media (min-width: map-get($grid-sizes, "md")) {}

@media (min-width: map-get($grid-sizes, "lg")) {
    #contact-header {
        height: 90vh;
        padding: 320px 15% 0px 15%;

        button {
            margin-top: 40px;
        }
    }
}

@media (min-width: map-get($grid-sizes, "xl")) {}

@media (min-width: map-get($grid-sizes, "xxl")) {
    #contact-header {
        h1 {
            font-size: 60px;
        }

        button {
            font-size: 20px;
        }
    }
}

@media (min-width: map-get($grid-sizes, "xxxl")) {}



/* Mobile specific */
@media (max-width: map-get($grid-sizes, "xl")) and (orientation:portrait) {
    #contact-header {
        button {
            margin-bottom: 80px;
        }
    }
}

@media (max-width: map-get($grid-sizes, "xs")) and (orientation:portrait) {
    #contact-header {
        button {
            margin-bottom: 0px;
        }
    }
}

@media (max-width: map-get($grid-sizes, "xxs")) and (orientation:portrait) {}