#otherSubview .row:first-child {
    height: 100%;
    flex: 1;
}

#otherSubview article {
    font-size: 14px;
}

#otherSubview li {
    font-size: 14px;
}

#otherSubview .col-4:first-child {
    padding-right: 20px
}

#otherSubview .container-fluid .row:first-child {
    height: 100px;
}



@media (min-width: map-get($grid-sizes, "xs")) {
    #otherSubview .container-fluid .row:first-child {
        height: 50px;
    }

    #otherSubview .container-fluid {
        padding-right: 20px;
    }

    #otherSubview .row {

        header {
            font-size: 20px;
        }

        h2 {
            font-size: 30px;
        }

        article {
            font-size: 12px;
            margin-top: 34px;
        }

        li {
            font-size: 12px;
        }

        span {
            font-size: 10px;
        }


    }
}

@media (min-width: map-get($grid-sizes, "sm")) {}

@media (min-width: map-get($grid-sizes, "lg")) {
    #otherSubview .container-fluid .row:first-child {
        height: 100px;
    }

    #otherSubview .row {
        header {
            font-size: 40px;
        }

        h2 {
            font-size: 40px;
        }

        li {
            font-size: 14px;
        }

        article {
            font-size: 14px;
            margin-top: 0px;
        }
    }
}

@media (min-width: map-get($grid-sizes, "xl")) {
    #otherSubview .row {

        header {
            font-size: 40px;
        }

        h2 {
            font-size: 40px;
        }

        li {
            font-size: 16px;
        }

        article {
            font-size: 16px;
        }
    }
}

@media (min-width: map-get($grid-sizes, "xxxl")) {
    #otherSubview .row {

        header {
            font-size: 40px;
        }

        h2 {
            font-size: 40px;
        }

        li {
            font-size: 18px;
        }

        article {
            font-size: 18px;
        }
    }
}

@media (max-width: map-get($grid-sizes, "lg")) and (orientation:portrait) {
    #otherSubview .row {
        padding-right: 20px;
    }
}