/* Sidebar Navigation */

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

li {
    line-height: inherit;
}

.side {
    position: fixed;
    top: 42%;
    left: 13px;
    z-index: 1000;
}

@-webkit-keyframes fadeout {
    0% {
        opacity: 1
    }

    75% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

@keyframes fadeout {
    0% {
        opacity: 1
    }

    75% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

.side * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.side .thumb {
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 23px;
    margin: 5px 0 0 14px;
    background-color: #E2001A
}

@media (min-width: 768px) {
    .side .thumb {
        margin: 5px 0 0 14px
    }
}




.side li {
    position: relative;
    margin: 0;
    cursor: pointer;
    width: 60px
}

.side li.selected .label {
    margin-left: 28px;
    -webkit-animation: fadeout 2s ease .2s;
    animation: fadeout 2s ease .2s
}

.side li.expanded .main {
    width: 2px;
    opacity: 1
}

.side li.expanded .drawer {
    height: 48px
}

.side li .main {
    position: relative;
    width: 3px;
    height: 23px;
    background-color: #333;
    margin: 5px 0 0 14px;
    -webkit-transition: height .2s ease;
    transition: height .2s ease;
    opacity: .25
}

.side li .drawer {
    position: relative;
    width: 2px;
    height: 0;
    background-color: #333;
    margin: 0 0 5px 14px;
    -webkit-transition: height .2s ease;
    transition: height .2s ease
}

.side li .label {
    font-family: Gotham Bold, "M Hei PRC W45", "M Hei HK W42", "M Hei HK W40", "HelveticaNeue-Regular", "Helvetica Neue Regular", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 10px;
    letter-spacing: .02em;
    color: #333;
    float: left;
    text-transform: uppercase;
    width: 300px;
    margin: 6px 0 0 24px;
    pointer-events: none;
    -webkit-transition: opacity .1s ease;
    transition: opacity .1s ease;
    opacity: 0;
}

.side li.contracted,
.side li.expanded {
    cursor: default
}

.side li.contracted:hover .label,
.side li.expanded:hover .label {
    opacity: 0
}

.side li.contracted .main {
    height: 8px
}

.side li:hover {
    opacity: 1
}

.side li:hover .label {
    opacity: 1
}

@media (min-width: 768px) {
    .side li .main {
        margin: 5px 0 0 14px
    }

    .side li .drawer {
        margin: 0 0 5px 14px
    }
}

.side.for-dark-screen .thumb {
    background-color: #E2001A
}

.side.for-dark-screen li .drawer,
.side.for-dark-screen li .main {
    background-color: #fff
}

.side.for-dark-screen li .label {
    color: #fff
}

.drawer.dr-exterior .colors-label h3,
.drawer.dr-exterior .colors-label h4,
.drawer.dr-exterior .colors-label p,
.drawer.dr-exterior .rim-detail-options h3,
.drawer.dr-exterior .rim-detail-options h4,
.drawer.dr-exterior .rim-detail-options p {
    color: #000
}

.drawer.dr-exterior .colors-options p {
    color: #000
}

.drawer.dr-exterior .rim-detail-options img {
    max-width: inherit
}

.drawer.dr-exterior .rim-detail-options p {
    margin-top: 5px !important
}

.drawer.dr-charging .frame h3 {
    line-height: normal
}

.drawer.dr-safety .ratings .frame h3 {
    line-height: inherit
}

.drawer .drawer-footer .quarter h5 {
    margin-top: 26px;
    margin-bottom: 26px
}
