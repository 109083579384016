@import "../../../dist/variables";
@import "../../../dist/functions";


@media (min-width: 0) {
    #references-body {
        .slick-slide {
            height: 46vh;
        }

        .slick-slide div {
            height: 46vh;
        }
    }

    #references-side {
        -ms-flex: 0 0 50vh;
        flex: 0 0 50vh;
        padding: 0px 10px 0px 40px;

        h1 {
            font-size: 30px;
        }

        p {
            font-size: 14px;
        }

        h1 {
            margin-bottom: 20px;
        }

        .col:first-child {
            -ms-flex: none;
            flex: none;
        }
    }
}

@media (min-width: map-get($grid-sizes, "xxs")) {}

@media (min-width: map-get($grid-sizes, "xs")) {
    #references-side {
        padding: 0px 40px 0px 80px;

        h1 {
            margin-bottom: 20px;
        }
    }
}

@media (min-width: map-get($grid-sizes, "sm")) {
    #references-body {
        .slick-slide {
            height: 63vh;
        }

        .slick-slide div {
            height: 63vh;
        }
    }

    #references-side {
        -ms-flex: 0 0 33vh;
        flex: 0 0 33vh;
        padding: 0px 20px 0px 40px;

        h1 {
            margin-bottom: 0px;
            font-size: 30px;
        }

        .col:first-child {
            -ms-flex: 0 0 20vw;
            flex: 0 0 20vw;
        }
    }
}

@media (min-width: map-get($grid-sizes, "md")) {}

@media (min-width: map-get($grid-sizes, "lg")) {
    #references-body {
        .slick-slide {
            height: 66vh;
        }

        .slick-slide div {
            height: 66vh;
        }
    }

    #references-side {
        .col:first-child {
            -ms-flex: 0 0 30vw;
            flex: 0 0 30vw;
        }

        h1 {
            font-size: 40px;
        }

        p {
            font-size: 18px;
        }
    }
}

@media (min-width: map-get($grid-sizes, "xl")) {
    #references-side {
        -ms-flex: 0 0 30vh;
        flex: 0 0 30vh;
        padding: 0px 80px 0px 80px;

        h1 {
            margin-bottom: 0px;
            font-size: 40px;
        }
    }
}

@media (min-width: map-get($grid-sizes, "xxl")) {
    #references-body {
        .slick-slide {
            height: 72vh;
        }

        .slick-slide div {
            height: 72vh;
        }
    }

    #references-side {
        .col:first-child {
            -ms-flex: 0 0 24vw;
            flex: 0 0 24vw;
        }

        p {
            font-size: 18px;
            padding: 0px 20px 0px 80px;
        }

        h1 {
            font-size: 40px;
        }
    }
}

@media (min-width: map-get($grid-sizes, "xxxl")) {

    #references-side {
        p {
            font-size: 20px;
        }

        h1 {
            font-size: 40px;
        }
    }
}



/* Mobile specific */
@media (max-width: map-get($grid-sizes, "xl")) and (orientation:portrait) {
    #references-body {
        .slick-slide {
            height: 50vh;
        }

        .slick-slide div {
            height: 50vh;
        }

        .slick-slider {
            .slick-item-image {
                height: 50vh;
                width: auto;
            }
        }
    }

    #references-side {
        -ms-flex: 0 0 50vh;
        flex: 0 0 50vh;
        padding: 0px 40px 0px 80px;

        h1 {
            margin-bottom: 20px;
            font-size: 40px;
        }

        p {
            font-size: 20px;
        }

        .col:first-child {
            -ms-flex: none;
            flex: none;
        }
    }
}

@media (max-width: map-get($grid-sizes, "md")) and (orientation:portrait) {
    #references-body {
        .slick-slide {
            height: 50vh;
        }

        .slick-slide div {
            height: 50vh;
        }

        .slick-slider {
            .slick-item-image {
                height: 50vh;
                width: auto;
            }
        }
    }

    #references-side {
        -ms-flex: 0 0 50vh;
        flex: 0 0 50vh;
        padding: 0px 20px 0px 40px;

        h1 {
            margin-bottom: 20px;
            font-size: 30px;
        }

        p {
            font-size: 14px;
        }
    }
}

@media (max-width: map-get($grid-sizes, "xs")) and (orientation:portrait) {
    #references-body {
        .slick-slide {
            height: 40vh;
        }

        .slick-slide div {
            height: 40vh;
        }

        .slick-slider {
            .slick-item-image {
                height: 40vh;
                width: auto;
            }
        }
    }

    #references-side {
        -ms-flex: 0 0 60vh;
        flex: 0 0 60vh;
        padding: 0px 20px 0px 40px;

        h1 {
            margin-bottom: 20px;
            font-size: 30px;
        }

        p {
            font-size: 14px;
        }
    }
}

@media (max-width: map-get($grid-sizes, "xxs")) and (orientation:portrait) {}